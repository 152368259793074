<template>
  <div class="king">
    <div class="super">
      <div class="holder">
        <Header />
        <div class="wrap container-fluid">
          <div class="body_wrap">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <div class="personal_details">
                  <form class="">
                    <h4>Landlord’s details</h4>
                    <div class="form-group">
                      <label for="fname">First name *</label>
                      <input
                        v-model="firstname"
                        type="text"
                        class="form-control"
                        id="fname"
                      />
                    </div>
                    <div class="form-group">
                      <label for="lname">Last name *</label>
                      <input
                        v-model="lastname"
                        type="text"
                        class="form-control"
                        id="lname"
                      />
                    </div>
                    <div class="form-group">
                      <label for="phoen">Phone *</label>
                      <input
                        v-model="phone"
                        type="text"
                        class="form-control"
                        id="phone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email address</label>
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="personal_details">
                  <form>
                    <h4>Property details</h4>

                    <div class="form-group">
                      <label for="type">Property type *</label>
                      <input
                        v-model="propertytype"
                        type="text"
                        class="form-control"
                        id="type"
                      />
                    </div>
                    <div class="form-group">
                      <label for="location">Location(Optional)</label>
                      <input
                        v-model="location"
                        type="text"
                        class="form-control"
                        id="location"
                      />
                    </div>
                    <div class="form-group">
                      <label for="rent">Rent value</label>
                      <input
                        v-model="rentvalue"
                        type="number"
                        class="form-control"
                        id="rent"
                      />
                    </div>
                    <div class="form-group">
                      <label for="wma">Who manages the apartment? *</label>
                      <select
                        class="form-control"
                        id="wma"
                        v-model="whomanages"
                      >
                        <option disabled value="">Select</option>
                        <option value="layer">Lawyer</option>
                        <option value="agent">Agent</option>
                        <option value="landlord">Landlord</option>
                        <option value="care taker">Care taker</option>
                        <option value="none">No one</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <div class="submit_btn">
                        <button
                          class="btn btn-block"
                          @click.prevent="payMyRent()"
                        >
                          Submit
                          <i
                            class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                            v-if="loader"
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div class="form-group" v-if="errors.length">
                      <ul class="error-ul">
                        <li
                          v-for="error in errors"
                          :key="error.error"
                          class="errorMessage"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterMain />
    <!-- Footer-ends -->
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import { Service } from "../store/service";
import $ from "jquery";
const Api = new Service();

export default {
  name: "RentPayerLandlord",
  components: {
    Header,
    FooterMain
  },
  data() {
    return {
      loader: false,
      errors: [],
      email: "",
      phone: "",
      lastname: "",
      firstname: "",
      propertytype: "",
      rentvalue: "",
      location: "",
      whomanages: ""
    };
  },
  mounted() {},
  methods: {
    payMyRent: async function() {
      this.loader = true;
      this.errors = [];
      try {
        if (
          this.firstname === "" ||
          this.lastname === "" ||
          this.email === "" ||
          this.phone === "" ||
          this.propertytype === "" ||
          this.rentvalue === "" ||
          this.location === "" ||
          this.whomanages === ""
        ) {
          this.errors.push("All fields are required!");
          this.loader = false;
        } else {
          await Api.postRequestNoAuth(`paymyrentlandlord`, {
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.email,
            propertytype: this.propertytype,
            rentvalue: this.rentvalue,
            location: this.location,
            whomanages: this.whomanages
          })
            .then(res => {
              if (
                res.data.error === "email has been used already" ||
                res.data.error
              ) {
                this.$store.commit("setApiFailed", res.data.error);
                this.loader = false;
              } else {
                // console.log('rent pament recieved', res);
                this.errors = "";
                this.$store.commit("setApiSuccess", "Success");
                this.loader = false;
                this.$router.push("/shelta");
              }
            })
            .catch(err => {
              this.loader = false;
              console.log(err);
              this.$store.commit("setApiFailed", "Error occured");
            });
        }
      } finally {
        console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
// Gotham: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
// Gotham2: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.errorMessage {
  font-family: Gotham;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

.king {
}
.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.wrap {
  background: #f2f5fe;
  padding-top: 10rem;
  padding-bottom: 4rem;
  .body_wrap {
    background: $whiteText;
    border-radius: 5px;
    margin: 0 13rem;
  }
}
.personal_details {
  padding: 30px 20px;
  text-align: left;
}
.property_details {
  padding: 30px 20px 1px 20px;
  text-align: left;
}
.form-control {
  // background: #635757;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
  outline: none;
  box-shadow: none;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.49);
}
.form-group {
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: -0.05em;
    color: secondary;
  }
}

.submit_btn {
  button {
    margin-top: 3.5rem;
    border-radius: 5px;
    background: $yellow;
    color: $whiteText;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    height: 50px;
    box-shadow: none;
    // line-height: 25px;
    letter-spacing: -0.05em;
  }
}

form {
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.05em;
    color: $secondary;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 599px) {
  .wrap {
    padding-top: 7rem;
    .body_wrap {
      margin: 0 10px;
    }
  }
  form {
    h4 {
      font-size: 20px;
    }
  }
  .form-group {
    label {
      font-size: 15px;
    }
  }
  .form-control {
    height: 40px;
    font-size: 14px;
  }
  .submit_btn {
    button {
      height: 40px;
      font-size: 15px;
    }
  }
}

@media only screen and (width: 768px) {
  .wrap {
    padding-top: 8rem;
    .body_wrap {
      margin: 0 2rem;
    }
  }
  form {
    h4 {
      font-size: 17px;
      padding-bottom: 1rem;
    }
  }
  .form-group {
    label {
      font-size: 14px;
    }
  }
  .form-control {
    height: 35px;
    font-size: 16px;
  }
  .submit_btn {
    button {
      height: 35px;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}
</style>
